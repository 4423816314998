@import "../../assets/styles/colors";

.fbContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  p, a {
    display: block;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}