@import "../../assets/styles/colors";

.credentialsContainer {
  margin-left: 20px;
  padding-top: 2px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.credentialPopup {
  margin-top: 40px;

  p {
    margin-bottom: 3px;
  }
}
.offer {
  margin-left: 20px;
}